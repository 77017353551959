import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { FormatBlueprint } from '@/components/builder/details/blueprints/FormatBlueprint';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { ShareForTypes } from '@/components/builder/form/enums/ShareForTypes';

export const Definition: BlueprintDefinition = {
    type: 'field',
    name: '[[[Pole]]]',
    icon: 'fa-arrows-alt-h',
    group: 'form-fields',
};

export interface FieldContract extends Blueprint, VisibleBlueprint, FormatBlueprint, HasWidth {
    headerName: Record<string, string>;
    boldLabel: boolean;
    boldValue: boolean;
    colorLabel: string;
    colorValue: string;
    tooltip: Record<string, string>;
    icon: string;
    shareFor: any;
}

export class FieldType implements FieldContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public shareFor: any;
    public headerName: Record<string, string>;
    public boldLabel: boolean;
    public boldValue: boolean;
    public showLabelAboveValue: boolean;
    public isLabelCustomWidth: boolean;
    public labelWidth: number;
    public colorLabel: string;
    public colorValue: string;
    public tooltip: Record<string, string>;
    public icon: string;
    public visible: AlwaysChoice | WhenChoice;
    public visibleWhen: string;
    public format: AlwaysChoice | WhenChoice;
    public formatWhen: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.headerName = {};
        this.shareFor = this.newShareForTypes();
        this.boldLabel = false;
        this.boldValue = false;
        this.showLabelAboveValue = false;
        this.isLabelCustomWidth = false;
        this.labelWidth = 150;
        this.colorLabel = '';
        this.colorValue = '';
        this.tooltip = {};
        this.icon = '';
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.format = AlwaysChoice.Always;
        this.formatWhen = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public setDefinition(definition: BlueprintDefinition): void
    {
        this.name = definition?.control ?? this.name;
        this.headerName = definition?.nameDictionary ?? {};
    }

    private newShareForTypes(): any
    {
        const values = Object.values(ShareForTypes).filter((v) => isNaN(Number(v)));

        const shareFor: Record<string, boolean> = {};

        values.forEach((p) => (shareFor[p] = true));

        return shareFor;
    }
}
