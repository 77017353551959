<script lang="ts" setup>
import { ref, computed, inject, Ref, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useLocalization } from '@/plugins/localization';
import { FieldType } from '.';
import { Column, ColumnDefinitionModel, IMetaModel } from '@/modules/core/common/services/GridService';
import { ShareForTypes } from '@/components/builder/form/enums/ShareForTypes';
import { Attachment } from '@/modules/low-code/services/ShareOneDriveService';
import { getAttributes } from '@/components/common/dynamic-grid/helpers/TypeOfData';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { DetailsBuilderContract } from '@/components/builder/details';
import DetailsLabel from '@/components/common/DetailsLabel.vue';
import ParticipantList from '@/modules/low-code/components/ParticipantList.vue';
import properties from '../../properties';

// Presenters
import DateTimePresenter from './presenters/DateTime.vue';
import UserWithAvatarPresenter from './presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from './presenters/UserWithAvatarArray.vue';
import TextPresenter from './presenters/Text.vue';
import StatusPresenter from './presenters/StatusWithVariant.vue';
import MoneyPresenter from './presenters/Money.vue';
import BooleanPresenter from './presenters/Boolean.vue';
import GenericKeyValuePairPresenter from './presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from './presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from './presenters/Decimal.vue';
import AddressPresenter from './presenters/Address.vue';
import MainFilePresenter from './presenters/File.vue';
import ListPresenter from './presenters/List.vue';
import SimplePartnerPresenter from './presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from './presenters/SimplePartnerArray.vue';
import DurationPresenter from './presenters/Duration.vue';
import ResourcePresenter from './presenters/Resource.vue';
import TablePresenter from './presenters/Table.vue';
import TableVatPresenter from './presenters/TableVat.vue';
import UserGroupArrayPresenter from './presenters/UserGroupArray.vue';
import ExchangeRatePresenter from './presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from './presenters/DynamicDocument.vue';
import IsPrivatePresenter from './presenters/IsPrivate.vue';
import RichTextPresenter from './presenters/RichText.vue';

defineOptions({
    name: 'FieldBlueprint',
    components: {
        DetailsLabel,
        ParticipantList,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const { $t } = useLocalization();
const route = useRoute();

const values = inject<Ref<any>>('values', null);
const configs = inject<ComputedRef<[string, Column][]>>('configs', null);
const schema = inject<Ref<ColumnDefinitionModel>>('schema', null);
const actions = inject<Ref<Record<string, IMetaModel>>>('actions', null);
const oneDriveSharedDocuments = inject<Ref<Array<Attachment>>>('oneDriveSharedDocuments', ref([]));

const blueprint = computed(() => props.blueprint);
const licence = computed(() => route.params.licence as string);
const publicId = computed(() => route.params.publicId as string);
const design = computed(() => props.details.designMode());

const value = computed(() =>
{
    if (design.value) return null;

    if (!values.value) return null;

    const value = values.value.result[blueprintName.value];

    return value;
});

const config = computed(() =>
{
    if (!configs.value || design.value) return null;

    const config = configs.value.find((config) => config[0] === blueprintName.value);

    return config;
});

const blueprintName = computed(() => blueprint.value.name.charAt(0).toLowerCase() + blueprint.value.name.slice(1));
const sharedField = computed(() => blueprintName.value === 'shared');
const isPrivateField = computed(() => blueprintName.value === 'isPrivate');
const isIconCodeProper = computed(() =>
{
    const iconRegex = new RegExp('^fa[a-z-\\s]+$');

    return iconRegex.test(blueprint.value.icon);
});

const label = computed(() => props.details.localization.translate(blueprint.value.headerName));
const isFormat = computed(() => blueprint.value.format === AlwaysChoice.Always || design.value);
const tooltip = computed(() =>
    blueprint.value.tooltip && isFormat.value ? props.details.localization.translate(blueprint.value.tooltip) : ''
);
const boldLabel = computed(() => isFormat.value && blueprint.value.boldLabel);
const boldValue = computed(() => isFormat.value && blueprint.value.boldValue);
const colorLabel = computed(() => (isFormat.value ? blueprint.value.colorLabel : ''));
const colorValue = computed(() => (isFormat.value ? blueprint.value.colorValue : ''));
const icon = computed(() => (isFormat.value ? blueprint.value.icon : ''));
const showLabelAboveValue = computed(() => isFormat.value && blueprint.value.showLabelAboveValue);
const isLabelCustomWidth = computed(() => isFormat.value && blueprint.value.isLabelCustomWidth);
const labelWidth = computed(() => isFormat.value && blueprint.value.labelWidth);

const settings = computed(() => ({
    boldLabel: boldLabel.value,
    boldValue: boldValue.value,
    tooltip: tooltip.value,
    colorLabel: colorLabel.value,
    colorValue: colorValue.value,
    icon: icon.value,
    showLabelAboveValue: showLabelAboveValue.value,
    isLabelCustomWidth: isLabelCustomWidth.value,
    labelWidth: labelWidth.value,
}));

const shareForTypes = computed(() =>
{
    return [
        {
            key: 1,
            value: $t('[[[Użytkownik]]]'),
        },
        {
            key: 2,
            value: $t('[[[Dział]]]'),
        },
        {
            key: 3,
            value: $t('[[[Zespół]]]'),
        },
    ];
});

const typeOfData = (value: any, config: [string, Column]) =>
{
    const [itemKey, headerOptions] = config;
    const property = value;
    const type = headerOptions.type.baseType;
    const features = headerOptions.type.features;

    const attributes = {
        property,
        features,
        ...getAttributes(type, {
            schema: schema.value,
            itemKey,
            property,
            actions: actions.value,
            oneDriveSharedDocuments: oneDriveSharedDocuments.value,
        }),
    };

    return attributes;
};
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <details-label :label="label" :settings="settings">
                <template #default>
                    <component
                        v-if="!design && config"
                        :is="typeOfData(value, config).is"
                        v-bind="{ ...typeOfData(value, config) }"
                    />
                    <participant-list
                        v-else-if="!design && sharedField"
                        :public-id="publicId"
                        :licence="licence"
                        :can-manage-participants="actions?.canManageParticipants?.can || false"
                        :blueprint="blueprint"
                    />
                    <is-private-presenter
                        v-else-if="!design && isPrivateField && values != null"
                        :public-id="publicId"
                        :licence="licence"
                        :can-toggle="actions?.canSetPrivate?.can ?? false"
                        :value="value"
                    />
                    <span v-else>-</span>
                </template>
            </details-label>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <ideo-form-localize v-slot="{ locale }">
                        <field-text v-model="blueprint.headerName[locale]" :label="$t('[[[Etykieta]]]')" />
                    </ideo-form-localize>
                    <div class="alert alert-light p-2 pb-0">
                        <h6>{{ $t('[[[Formatowanie]]]') }}</h6>
                        <hr class="mt-1 mb-2" />
                        <field-format :details="details" :blueprint="blueprint" />
                        <field-checkbox v-model="blueprint.boldLabel" :label="$t('[[[Pogrubienie etykiety]]]')" />
                        <field-checkbox v-model="blueprint.boldValue" :label="$t('[[[Pogrubienie wartości]]]')" />
                        <ideo-form-group :label="$t('[[[Kolor etykiety]]]')">
                            <input v-model="blueprint.colorLabel" type="color" class="d-block w-100" />
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Kolor wartości]]]')">
                            <input v-model="blueprint.colorValue" type="color" class="d-block w-100" />
                        </ideo-form-group>
                        <field-checkbox
                            v-model="blueprint.showLabelAboveValue"
                            :label="$t('[[[Pokaż etykietę nad wartością]]]')"
                        />
                        <field-checkbox v-model="blueprint.isLabelCustomWidth" :label="$t('[[[Szerokość niestandardowa etykiety]]]')" />
                        <field-numeric
                            v-if="blueprint.isLabelCustomWidth"
                            v-model="blueprint.labelWidth"
                            :label="$t('[[[Szerokość dla etykiety]]]')"
                            append="px"
                            :min="0"
                            :max="1000"
                        />
                        <ideo-form-localize v-slot="{ locale }">
                            <field-text
                                v-model="blueprint.tooltip[locale]"
                                :label="$t('[[[Tooltip]]]')"
                                class="flex-fill"
                            />
                        </ideo-form-localize>
                        <ideo-form-group :label="$t('[[[Ikona]]]')">
                            <template #default>
                                <div class="d-flex align-items-center">
                                    <ideo-form-input
                                        v-model="blueprint.icon"
                                        type="text"
                                        name="icon"
                                        class="flex-fill"
                                    />
                                    <div class="d-flex justify-content-center">
                                        <i
                                            v-if="isIconCodeProper"
                                            :class="blueprint.icon"
                                            class="ms-2"
                                            style="font-size: 20px"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template #description>
                                {{ $t('[[[Wpisz kod ikony Font Awesome, aby zobaczyć podgląd]]]') }}
                            </template>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Udostępnij dla]]]')" v-if="sharedField">
                            <template v-for="option in shareForTypes" :key="option.key">
                                <field-checkbox
                                    v-model="blueprint.shareFor[ShareForTypes[option.key]]"
                                    :label="option.value"
                                />
                            </template>
                        </ideo-form-group>
                    </div>
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>
