export default [
    // -------------------------------------------------------------------------------
    // Strona główna
    // -------------------------------------------------------------------------------
    {
        name: '[[[Strona główna]]]',
        short: '[[[Start]]]',
        route: 'dashboard',
        icon: 'fal fa-house-chimney',
        children: [
            {
                name: '[[[Profil użytkownika]]]',
                route: 'core-account-profile',
                icon: 'far fa-fw fa-user-circle',
                hiddenInImpersonated: true
            },
            {
                name: '[[[Zmiana hasła]]]',
                route: 'core-account-password',
                icon: 'far fa-fw fa-toggle-on',
                hiddenInImpersonated: true,
                hiddenForAccountType: ['Ldap', 'NoLogon']
            },
            {
                name: '[[[Centrum powiadomień]]]',
                route: 'core-account-notifications',
                icon: 'far fa-fw fa-envelope-open',
                hiddenInImpersonated: true
            },
            {
                name: '[[[Bezpieczeństwo]]]',
                route: 'core-account-security',
                icon: 'far fa-fw fa-phone-laptop',
                hiddenInImpersonated: true
            },
            {
                name: '[[[Zastępstwa]]]',
                route: 'account-substitutions',
                icon: 'far fa-fw fa-user',
                auth: { all: ['CorePermissions@MyAccount'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie zastępstwa]]]',
                        route: 'account-substitutions-add',
                        visible: false
                    },
                    {
                        name: '[[[Edycja zastępstwa]]]',
                        route: 'account-substitutions-edit',
                        visible: false
                    }
                ]
            }
        ]
    },

    // -------------------------------------------------------------------------------
    // System
    // -------------------------------------------------------------------------------
    {
        name: '[[[System]]]',
        short: '[[[System]]]',
        icon: 'fal fa-display',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Administracja]]]',
                icon: 'far fa-fw fa-folder-user',
                children: [
                    {
                        name: '[[[Użytkownicy]]]',
                        route: 'core-users',
                        auth: { all: ['UsersPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie użytkownika]]]',
                                route: 'core-users-add',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja użytkownika]]]',
                                route: 'core-users-edit',
                                auth: { all: ['UsersPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Spółki]]]',
                        route: 'core-companies',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@CompaniesManager'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie spółki]]]',
                                route: 'core-companies-add',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły spółki]]]',
                                route: 'core-companies-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Stanowiska pracy]]]',
                        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
                        auth: { all: ['UsersPermissions@View'], any: [] },
                        route: 'core-jobPositions',
                    },
                    {
                        name: '[[[Role i uprawnienia]]]',
                        route: 'core-roles',
                        auth: { all: ['RolesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie roli]]]',
                                route: 'core-roles-add',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja roli]]]',
                                route: 'core-roles-edit',
                                auth: { all: ['RolesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Organizacja]]]',
                        route: 'core-units',
                        auth: { all: ['OrganizationPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie działu]]]',
                                route: 'core-units-add',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja działu]]]',
                                route: 'core-units-edit',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zespoły]]]',
                        route: 'core-teams',
                        auth: { all: ['TeamsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zespołu]]]',
                                route: 'core-teams-add',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zespołu]]]',
                                route: 'core-teams-edit',
                                auth: { all: ['TeamsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zastępstwa]]]',
                        route: 'core-substitutions',
                        auth: { all: ['SubstitutionsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zastępstwa]]]',
                                route: 'core-substitutions-add',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zastępstwa]]]',
                                route: 'core-substitutions-edit',
                                auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Ustawienia]]]',
                icon: 'far fa-fw fa-cog',
                children: [
                    {
                        name: '[[[Bezpieczeństwo]]]',
                        route: 'core-security',
                        auth: { all: ['AdminPermissions@Security'], any: [] }
                    },
                    {
                        name: '[[[Słowniki]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'core-dictionaries',
                        auth: { all: ['CorePermissions@Dictionaries'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj słownik]]]',
                                route: 'core-dictionaries-add',
                                visible: false
                            },
                            {
                                name: '[[[Edycja słownika]]]',
                                route: 'core-dictionaries-edit',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Terminy słowników]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'core-dictionaryTerms',
                        auth: { all: ['CorePermissions@Dictionaries'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj termin]]]',
                                route: 'core-dictionaryTerms-add',
                                visible: false
                            },
                            {
                                name: '[[[Edycja terminu]]]',
                                route: 'core-dictionaryTerms-edit',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Tabele wektorowe]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'vector-collections',
                        auth: { all: ['CorePermissions@VectorDbAdmin'], any: [] },
                    },
                    {
                        name: '[[[Dane wektorowe]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'collection-entries',
                        auth: { all: ['CorePermissions@VectorDbAdmin'], any: [] },
                    },
                    {
                        name: '[[[Szablony dokumentów]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'core-print-templates',
                        auth: { all: ['CorePermissions@CanAddTemplates'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie szablonu]]]',
                                route: 'core-print-templates-form',
                                visible: false
                            },
                            {
                                name: '[[[Edycja szablonu]]]',
                                route: 'core-print-templates-edit',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły]]]',
                                route: 'core-print-templates-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Źródła danych]]]',
                        namespace: 'Ideo.Logito.Common',
                        route: 'integrators',
                        auth: { all: ['CorePermissions@CanManageGlobalIntegrators'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj źródło danych]]]',
                                route: 'integrators-add',
                                visible: false
                            },
                            {
                                name: '[[[Edytuj źródło danych]]]',
                                route: 'integrators-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Powiadomienia]]]',
                        route: 'core-notifications-templates',
                        auth: { all: ['NotificationsPermissions@Access'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj szablon]]]',
                                route: 'core-notifications-templates-add',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-notifications-templates-edit',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Powiadomienia zbiorcze]]]',
                        route: 'core-bulk-notifications',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@BulkNotifications'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj]]]',
                                route: 'core-bulk-notifications-add',
                                auth: { all: ['CorePermissions@BulkNotifications'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-bulk-notifications-edit',
                                auth: { all: ['CorePermissions@BulkNotifications'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Źródła LDAP]]]',
                        route: 'core-ldap',
                        auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie źródła]]]',
                                route: 'core-ldap-add',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja źródła]]]',
                                route: 'core-ldap-edit',
                                auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Poczta]]]',
                        route: 'core-mailing',
                        icon: 'fa-regular fa-envelope',
                        auth: { all: ['AdminPermissions@Mailing'], any: [] }
                    },
                    {
                        name: '[[[Kody kontrolingowe]]]',
                        route: 'core-controlCodes-list',
                        namespace: 'Ideo.Logito.Common',
                        visible: false,
                        auth: { all: ['CorePermissions@ControlCodes'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj kod kontrolingowy]]]',
                                route: 'core-controlCodes-add',
                                auth: { all: ['CorePermissions@ControlCodes'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja kodu kontrolingowego]]]',
                                route: 'core-controlCodes-edit',
                                auth: { all: ['CorePermissions@ControlCodes'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Zmienne środowiskowe]]]',
                        route: 'core-env-variables',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@EnvVariables'], any: [] }
                    },
                    {
                        name: '[[[Moduły]]]',
                        route: 'lowCode-modules-list',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@Modules'], any: [] },
                        children: [
                            {
                                name: '[[[Dodawanie modułu]]]',
                                route: 'lowCode-modules-add',
                                visible: false
                            },
                            {
                                name: '[[[Dodawanie modułu]]]',
                                route: 'lowCode-modules-edit',
                                visible: false
                            },
                        ]
                    },
                    {
                        name: '[[[Sitemap]]]',
                        route: 'lowCode-sitemap',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@Modules'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie wpisu]]]',
                                route: 'lowCode-sitemap-add',
                                visible: false
                            },
                            {
                                name: '[[[Edycja wpisu]]]',
                                route: 'lowCode-sitemap-edit',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Pola wspólne]]]',
                        route: 'lowCode-shared-fields',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@Modules'], any: [] },
                        children: []
                    },
                    {
                        name: '[[[Wizualizacja]]]',
                        route: 'core-colors',
                        auth: { all: ['AdminPermissions@Colors'], any: [] }
                    },
                    {
                        name: '[[[Tłumaczenia]]]',
                        route: 'core-translations',
                        icon: 'far fa-fw fa-subtitles',
                        auth: { all: ['AdminPermissions@Translations'], any: [] }
                    },
                    {
                        name: '[[[OCR]]]',
                        route: 'core-ocr',
                        namespace: 'Ideo.Logito.Common',
                        icon: 'far fa-fw fa-file-magnifying-glass',
                        auth: { all: ['CorePermissions@Ocr'], any: [] }
                    },
                    {
                        name: '[[[Logi]]]',
                        route: 'core-logs-targets',
                        icon: 'far fa-fw fa-shield-alt',
                        namespace: 'Ideo.Logito.Common',
                        auth: { all: ['CorePermissions@Logs'], any: [] },
                        children: [
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-targets',
                                icon: 'far fa-fw fa-shield-alt',
                                namespace: 'Ideo.Logito.Common',
                                auth: { all: ['CorePermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-targets-add',
                                        namespace: 'Ideo.Logito.Common',
                                        auth: { all: ['CorePermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-targets-edit',
                                        namespace: 'Ideo.Logito.Common',
                                        auth: { all: ['CorePermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Logi]]]',
                                route: 'core-logs-rules',
                                icon: 'far fa-fw fa-shield-alt',
                                namespace: 'Ideo.Logito.Common',
                                auth: { all: ['CorePermissions@Logs'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-rules-add',
                                        namespace: 'Ideo.Logito.Common',
                                        auth: { all: ['CorePermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-rules-edit',
                                        namespace: 'Ideo.Logito.Common',
                                        auth: { all: ['CorePermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                name: '[[[Monitoring]]]',
                icon: 'far fa-fw fa-analytics',
                children: [
                    {
                        name: '[[[Historia logowania]]]',
                        route: 'core-authlog',
                        auth: { all: ['AdminPermissions@Authlog'], any: [] }
                    },
                    {
                        name: '[[[Harmonogram zadań]]]',
                        route: 'core-scheduler',
                        auth: { all: ['SchedulerPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Historia zadania]]]',
                                route: 'core-scheduler-history',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły zadania]]]',
                                route: 'core-scheduler-details',
                                visible: false
                            }
                        ]
                    },
                ]
            },
            {
                name: '[[[Licencja]]]',
                route: 'core-licence',
                icon: 'far fa-file-certificate',
                auth: { all: [], any: [
                    'UsersPermissions@View',
                    'CorePermissions@Modules',
                    'CorePermissions@EnvVariables',
                    'RolesPermissions@View'
                ] },
                visible: true
            }
        ]
    },

    // -------------------------------------------------------------------------------
    // Kontrahenci
    // -------------------------------------------------------------------------------
    {
        name: '[[[Kontrahenci]]]',
        short: '[[[Kontrahenci]]]',
        icon: 'fal fa-handshake',
        namespace: 'Ideo.Logito.Crm',
        auth: { all: ['CrmPartnerPermissions@Access'], any: [] },
        children: [
            {
                name: '[[[Lista kontahentów]]]',
                route: 'crm-bs-partners-list',
                icon: 'far fa-users-class',
                children: [
                    {
                        name: '[[[Dodaj kontrahenta]]]',
                        route: 'crm-bs-partners-form',
                        namespace: 'Ideo.Logito.Crm',
                        auth: { all: ['CrmPartnerPermissions@CanEdit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edytuj kontrahenta]]]',
                        route: 'crm-bs-partners-list-edit',
                        namespace: 'Ideo.Logito.Crm',
                        auth: { all: ['CrmPartnerPermissions@CanEdit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Szczegóły]]]',
                        route: 'crm-bs-partners-details',
                        visible: false
                    },
                    {
                        name: '[[[Importuj]]]',
                        route: 'crm-bs-partners-import-form',
                        namespace: 'Ideo.Logito.Crm',
                        auth: { all: ['CrmPartnerPermissions@CanEdit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Szczególy importu]]]',
                        route: 'crm-bs-partners-import-details',
                        namespace: 'Ideo.Logito.Crm',
                        auth: { all: ['CrmPartnerPermissions@CanEdit'], any: [] },
                        visible: false
                    }
                ]
            },
        ]
    },

    // -------------------------------------------------------------------------------
    // Kalendrz
    // -------------------------------------------------------------------------------
    {
        name: '[[[Kalendarz]]]',
        short: '[[[Kalendarz]]]',
        icon: 'fal fa-calendar',
        namespace: 'Ideo.Logito.Calendar',
        auth: { all: ['CalendarPermissions@Access'], any: [] },
        children: [
            {
                name: '[[[Mój kalendarz]]]',
                route: 'calendar',
                icon: 'far fa-calendar-alt',
                children: [
                    {
                        name: '[[[Edytuj termin]]]',
                        route: 'calendar-edit',
                        visible: false
                    },
                    {
                        name: '[[[Zaproszenia do akceptacji]]]',
                        route: 'calendar-invitations',
                        visible: false
                    },
                    {
                        name: '[[[Szczegóły terminu]]]',
                        route: 'calendar-invitation-details',
                        visible: false
                    }
                ]
            },
        ]
    },

    // -------------------------------------------------------------------------------
    // Pracownicy
    // -------------------------------------------------------------------------------
    {
        name: '[[[Pracownicy]]]',
        short: '[[[Pracownicy]]]',
        icon: 'fal fa-people-group',
        namespace: 'Ideo.Logito.Accounts.Staff',
        auth: { all: ['InfoStaffPermissions@Access'], any: [] },
        children: [
            {
                name: '[[[Lista pracowników]]]',
                route: 'staff-people',
                icon: 'far fa-user',
                children: [
                    {
                        name: '[[[Dział]]]',
                        route: 'staff-people-organization',
                        visible: false
                    },
                ]
            },
        ]
    },

    // -------------------------------------------------------------------------------
    // Biuro
    // -------------------------------------------------------------------------------
    {
        name: '[[[Biuro]]]',
        short: '[[[Biuro]]]',
        icon: 'fal fa-mail-bulk',
        namespace: 'Ideo.Logito.Office',
        auth: { all: ['OfficePermissions@Access'], any: [] },
        children: [
            {
                name: '[[[Do wprowadzenia]]]',
                icon: 'far fa-inbox',
                route: 'office-registerTasks'
            },
            {
                name: '[[[Raporty]]]',
                icon: 'far fa-chart-bar',
                children: [
                    {
                        name: '[[[Dziennik korespondencji]]]',
                        route: 'office-registerSearch'
                    },
                    {
                        name: '[[[Wyszukiwarka dokumentów]]]',
                        route: 'documents'
                    },
                    {
                        name: '[[[Budżet]]]',
                        namespace: 'Ideo.Logito.Permissions',
                        route: 'office-budget',
                        auth: { all: ['ReportPermissions@CanSeeBudget'], any: [] },
                    }
                ]
            }
        ]
    },
];
