<script lang="ts" setup>
import { computed, watch, getCurrentInstance, ref, inject, onMounted, onUnmounted } from 'vue';
import properties from '@/components/forms/properties';
import { FormContract, ProgressChoice } from '../form';
import { RowContract } from '../row';
import { PageType } from '.';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Events } from '@/components/builder/base/managers/EventManager';

const instance = getCurrentInstance();

defineOptions({
    name: 'page-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);
const parent = computed(() => props.parent);

const emit = defineEmits(["submit"]);

const { $t } = useLocalization();

if (props.blueprint.components.length == 0)
{
    props.form.layout.addRow(props.blueprint);
}

const title = computed(() => props.form.localization.translate(blueprint.value.title) || pageNumberLabel.value);
const description = computed(() => props.form.localization.translate(blueprint.value.description));
const prevButtonText = computed(() => props.form.localization.translate(blueprint.value.prevButtonText) || $t('[[[Wstecz]]]'));
const nextButtonText = computed(() => props.form.localization.translate(blueprint.value.nextButtonText) || $t('[[[Dalej]]]'));
const sendButtonText = computed(() => props.form.localization.translate(parent.value.sendButtonText) || $t('[[[Wyślij]]]'));

const refreshKey = ref(1);
const canEdit = inject('canEdit', false);
const design = computed(() => props.form.designMode());
const selected = computed(() => refreshKey.value > 0 && props.form.clipboard.isPageSelected(props.blueprint) && design.value);
const visible = computed(() =>
{
    return design.value || (props.form.expressions.visible(props.blueprint) && props.parent.currentPage == pageNumber.value);
});

onMounted(() =>
{
    props.form.events.subscribe(Events.FOCUS, onFocus);
});

onUnmounted(() =>
{
    props.form.events.release(Events.FOCUS, onFocus);
});

function onFocus()
{
    if (props.form.clipboard.isPageSelected(props.blueprint))
    {
        refreshKey.value++;
    }
}

const rows = computed<RowContract[]>(() => props.blueprint.components as RowContract[]);
const last = computed<RowContract>(() => rows.value.last());

const isFirst = computed(() =>
{
    const components = props.parent.components.filter(c => design.value || props.form.expressions.visible(c));

    return components.indexOf(props.blueprint) == 0;
});

const isLast = computed(() =>
{
    const components = props.parent.components.filter(c => design.value || props.form.expressions.visible(c));

    return components.indexOf(props.blueprint) == components.length - 1;
});

const showHeader = computed(() =>
{
    return design.value || props.blueprint.showTitle || !!description.value;
});

const showFooter = computed(() =>
{
    return props.parent.showSendButton || (
        props.parent.progressType != ProgressChoice.Tabs && (
            props.parent.components.length > 1 ||
            props.parent.showPageNumbers
        )
    );
});

const pageNumber = computed(() => props.parent.components.indexOf(props.blueprint) + 1);
const pageNumberLabel = computed(() => $t('[[[Strona %0|||{0}]]]', pageNumber.value));

const toolbar = computed(() => selected.value && props.parent.components.length > 1);
const progressChoice = computed(() => ProgressChoice);

const selectPage = (): void =>
{
    props.form.clipboard.selectPage(props.blueprint);
};

const addRow = (before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.clipboard.selectPlaceholder(row, null);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.clipboard.cut(component);
    props.form.clipboard.paste(row);
};

const pageValid = (): boolean =>
{
    const entries = props.form.schema.descendants(props.blueprint)
        .map(p =>
        {
            return { blueprint: p, entry: props.entry.find(p.name) };
        })
        .filter(p => p.entry != null);

    entries.forEach(p => p.entry.validate(p.blueprint, props.form));

    return entries.filter(p => !p.entry.valid()).length == 0;
};

const nextPage = (): void =>
{
    if (pageValid())
    {
        const components = props.parent.components.filter(c => props.form.expressions.visible(c));
        const index = components.indexOf(props.blueprint);

        if (index< components.length - 1)
        {
            parent.value.currentPage = props.parent.components.indexOf(components[index + 1]) + 1;
        }
    }
};

const prevPage = (): void =>
{
    const components = props.parent.components.filter(c => props.form.expressions.visible(c));
    const index = components.indexOf(props.blueprint);

    if (index > 0)
    {
        parent.value.currentPage = props.parent.components.indexOf(components[index - 1]) + 1;
    }
};

const submit = (): void =>
{
    emit('submit');
};

watch(() => last.value?.components.length, (length: number): void =>
{
    if (length && length > 0)
    {
        props.form.layout.addRow(props.blueprint);
    }
});

watch(selected, (value: boolean): void =>
{
    if (value)
    {
        props.form.dom.offsetTop(instance);
    }
});
</script>

<template>
    <form-component :class="{'page-component': design, 'selected': selected}" @click.stop="selectPage()" :form="form" :blueprint="blueprint" v-if="visible">
        <design-status :form="form" :blueprint="blueprint" />
        <div class="header" v-if="showHeader">
            <h2 v-if="blueprint.showTitle || design" :class="{'text-secondary': !blueprint.showTitle}">
                {{ title }}
            </h2>
            <p class="description" v-if="description">{{ description }}</p>
        </div>
        <template v-for="(component, index) in rows" :key="component.id">
            <design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :form="form" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0 && canEdit" />
            <component :is="form.schema.designer(component.type)" :form="form" :parent="blueprint" :blueprint="rows[index]" :entry="entry"></component>
        </template>
        <div class="page-component-footer" v-if="showFooter">
            <hr class="mt-0">
            <div class="row" :class="{'g-0': design}">
                <div class="col-4 col-sm-5 text-start">
                    <button class="btn btn-light" :disabled="design" @click.stop.prevent="prevPage()" v-if="!isFirst && parent.progressType != progressChoice.Tabs">
                        {{ prevButtonText }}
                    </button>
                </div>
                <div class="col-4 col-sm-2 text-center align-self-center">
                    <span v-if="parent.showPageNumbers">{{ pageNumberLabel }}</span>
                </div>
                <div class="col-4 col-sm-5 text-end">
                    <button class="btn btn-primary" :disabled="design" @click.stop.prevent="nextPage()" v-if="!isLast && parent.progressType != progressChoice.Tabs">
                        {{ nextButtonText }}
                    </button>
                    <button class="btn btn-primary" :disabled="design" @click.stop.prevent="submit()" v-if="isLast && parent.showSendButton">
                        {{ sendButtonText }}
                    </button>
                </div>
            </div>
        </div>
        <design-toolbar v-if="design && toolbar">
            <div class="btn-toolbar m-auto">
                <div class="btn-group">
                    <button type="button" class="btn btn-secondary" :title="$t('[[[Przesuń do góry]]]')" :disabled="isFirst" @click.stop.prevent="form.layout.moveUp(blueprint)">
                        <i class="far fa-arrow-up"></i>
                    </button>
                    <button type="button" class="btn btn-secondary" :title="$t('[[[Przesuń na dół]]]')" :disabled="isLast" @click.stop.prevent="form.layout.moveDown(blueprint)">
                        <i class="far fa-arrow-down"></i>
                    </button>
                </div>
                <div class="btn-group">
                    <button type="button" class="btn btn-danger" :title="$t('[[[Usuń]]]')" @click.stop.prevent="form.layout.removeComponent(blueprint)">
                        <i class="far fa-trash"></i>
                    </button>
                </div>
            </div>
        </design-toolbar>
        <portal to="form-toolbox" v-if="selected">
            <design-panel :form="form" :key="blueprint.name">
                <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
                <ideo-form-localize v-slot="{ locale }">
                    <field-text v-model="blueprint.title[locale]" :label="$t('[[[Tytuł]]]')" />
                </ideo-form-localize>
                <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł strony]]]')" />
                <ideo-form-localize v-slot="{ locale }">
                    <field-textarea v-model="blueprint.description[locale]" :label="$t('[[[Opis]]]')" />
                </ideo-form-localize>
                <ideo-form-localize v-slot="{ locale }">
                    <field-text v-model="blueprint.prevButtonText[locale]" :label="$t('[[[Nazwa przycisku ``Wstecz``]]]')" :placeholder="$t('[[[Wstecz]]]')" />
                </ideo-form-localize>
                <ideo-form-localize v-slot="{ locale }">
                    <field-text v-model="blueprint.nextButtonText[locale]" :label="$t('[[[Nazwa przycisku ``Dalej``]]]')" :placeholder="$t('[[[Dalej]]]')" />
                </ideo-form-localize>
                <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            </design-panel>
        </portal>
    </form-component>
</template>

<style lang="scss" scoped>
.page-component {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 10px 0;
    border: 2px solid var(--bs-border-color);

    &.selected {
        border-color: var(--bs-success);
    }

    .header {
        padding: 10px 10px 5px;
    }

    .page-component-footer {
        padding: 5px 10px 10px;
    }

    .description {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: justify;
    }
}

.header {
    border: none;
}
</style>
