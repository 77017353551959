<template>
    <div class="app d-flex">
        <div class="container">
            <div class="d-flex justify-content-center">
                <div class="d-flex position-relative flex-column align-items-center wrapper">
                    <div class="content">
                        <RouterView />
                    </div>
                    <p class="text-center copyright">
                        powered by LOGITO
                    </p>
                    <img src="/static/img/logito-logo-dark-xl.png" alt="logito-logo-xl" class="logo-background" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.app {
    height: 100vh;
    width: 100%;
    min-height: 650px;
    background: #30353B;
    display: flex;
    align-items: center;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding: 1rem 0;
    }

    .wrapper {
        width: 100%;
        z-index: 1;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            width: 522px;
        }

        .content {
            width: 100%;
        }

        .copyright {
            font-size: 12px;
            color: #CECECE;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .logo-background {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        transform: translate(-60%, -50%);
    }
}
</style>
