import { defineAsyncComponent, Plugin } from 'vue';
import BackButton from '@/modules/logito/common/components/BackButton.vue';

const LogitoComponentsPlugin: Plugin = {
    install(app)
    {
        app.component('BackButton', BackButton);

        app.component(
            'UserAvatar',
            defineAsyncComponent(() => import('@/components/common/UserAvatar.vue'))
        );
        app.component(
            'DynamicFilters',
            defineAsyncComponent(() => import('@/components/common/dynamic-filters/DynamicFilters.vue'))
        );

        app.component(
            'NewFiltersWrapper',
            defineAsyncComponent(() => import('@/components/common/new-filters/NewFiltersWrapper.vue'))
        );
        app.component(
            'IdeoNavTabs',
            defineAsyncComponent(() => import('@/modules/logito/common/components/IdeoNavTabs.vue'))
        );
        app.component(
            'FormAutocomplete',
            defineAsyncComponent(() => import('@/components/common/global-autocomplete/FormAutocomplete.vue'))
        );
        app.component(
            'ViewManager',
            defineAsyncComponent(() => import('@/components/common/view-manager/ViewManager.vue'))
        );
        app.component(
            'ViewActionButton',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid/ViewActionButton.vue'))
        );
        app.component(
            'IdeoSelect',
            defineAsyncComponent(() => import('@/components/common/global-autocomplete/IdeoSelect.vue'))
        );
        app.component(
            'DynamicGrid',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/DynamicGridNew.vue'))
        );
        app.component(
            'FilterManager',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/managers/Filter.vue'))
        );
        app.component(
            'ColumnManager',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/managers/Column.vue'))
        );
        app.component(
            'ExportManager',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/managers/Export.vue'))
        );
        app.component(
            'GridActions',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/actions/Grid.vue'))
        );
        app.component(
            'RowActions',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/actions/Row.vue'))
        );
        app.component(
            'GroupActions',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/actions/Group.vue'))
        );
        app.component(
            'FetchData',
            defineAsyncComponent(() => import('@/components/common/dynamic-grid-new/FetchData.vue'))
        );
    },
};

export default LogitoComponentsPlugin;
