import { KeyValuePair, Pagination, Resource, Statement } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { axios } from '@/plugins/axios';
import { merge } from 'lodash';
import { DateTime } from 'luxon';
import { DisplayMode, DisplaySize } from '@/modules/low-code/process/services/BpmnService';
import { Attachment } from '@/modules/low-code/services/ShareOneDriveService';

export default class GridService
{
    public static async fetchColumnsDefinition(
        requestUrl: string,
        actionName: string = 'list',
        parentType: string = null,
        parentId: string = null
    ): Promise<ColumnDefinitionModel>
    {
        if (parentType !== null)
        {
            return (await axios.get(`simple-schema/${parentType}/${parentId}/${requestUrl}/result`)).data;
        }

        // router props seems to put empty string except of undefined if actionName is not specified so I need to make sure it get's list set and not empty string
        if (!actionName) actionName = 'list';

        return (await axios.get(`simple-schema/${requestUrl}/${actionName}/result`)).data;
    }

    public static async fetchColumnsDefinitionNew(
        url: string,
        sitemapId: string = null,
        extraParams?: any
    ): Promise<ColumnDefinitionModel>
    {
        return (
            await axios.get(`simple-schema/${url}/result`, {
                params: merge({ sitemapId }, extraParams),
            })
        ).data;
    }

    public static async saveColumnsDefinition(
        definition: ColumnDefinitionModel,
        requestUrl: string,
        actionName: string = 'list',
        parentType: string = null,
        parentId: string = null,
        sitemapId: string = null
    ): Promise<ColumnDefinitionModel>
    {
        if (parentType != null)
        {
            return (
                await axios.post(`simple-schema/${parentType}/${parentId}/${requestUrl}/result`, {
                    columns: definition,
                    sitemapId,
                })
            ).data;
        }

        return (
            await axios.post(`simple-schema/${requestUrl}/${actionName}/result`, {
                columns: definition,
                sitemapId,
            })
        ).data;
    }

    public static async saveColumnsDefinitionNew(
        endpoint: string,
        definition: ColumnDefinitionModel,
        sitemapId: string = null
    ): Promise<ColumnDefinitionModel>
    {
        return (
            await axios.post(`simple-schema/${endpoint}/result`, { columns: definition }, { params: { sitemapId } })
        ).data;
    }

    public static async deleteConfiguration(
        requestUrl: string,
        actionName: string = 'list',
        parentType: string = null,
        parentId: string = null,
        sitemapId: string = null
    ): Promise<void>
    {
        if (parentType != null)
        {
            return (
                await axios.delete(`simple-schema/${parentType}/${parentId}/${requestUrl}/result`, {
                    params: { sitemapId },
                })
            ).data;
        }

        return (await axios.delete(`simple-schema/${requestUrl}/${actionName}/result`)).data;
    }

    public static async deleteConfigurationNew(endpoint: string, sitemapId: string = null): Promise<void>
    {
        return (
            await axios.delete(`simple-schema/${endpoint}/result`, {
                params: { sitemapId },
            })
        ).data;
    }

    public static async fetchData(
        url: string,
        filter: any,
        pager: Pager,
        actionName: string = '',
        parentType: string = null,
        parentId: string = null
    ): Promise<Pagination<Resource<Record<string, any>>>>
    {
        if (parentType != null)
        {
            return (
                await axios.get(`simple-query/${parentType}/${parentId}/${url}`, {
                    params: merge({}, filter, pager.data()),
                })
            ).data;
        }

        url = actionName ? `${url}/${actionName}` : url;

        return (
            await axios.get(`simple-query/${url}`, {
                params: merge({}, filter, pager.data()),
            })
        ).data;
    }

    public static async fetchDataNew(
        url: string,
        pager: Pager,
        filter: any,
        sitemapId?: string,
        extraParams?: any
    ): Promise<Pagination<Resource<Record<string, any>>>>
    {
        return (
            await axios.get(`simple-query/${url}`, {
                params: merge({ sitemapId }, filter, extraParams, pager.data()),
            })
        ).data;
    }

    public static async fetchActions(url: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`simple-query/${url}/actions`)).data;
    }

    public static async fetchFeatureListActions(
        url: string,
        parentId: string,
        parentType: string
    ): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`simple-query/${parentType}/${parentId}/${url}/actions`)).data;
    }

    public static async fetchSingleActions(url: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`simple-query/${url}`)).data;
    }

    public static async deleteRecord(url: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/${url}`)).data;
    }

    public static async exportElement(
        moduleId: string,
        actionName: string,
        templateGuid: string,
        filter: any,
        pager: Pager
    ): Promise<any>
    {
        if (actionName)
        {
            return (
                await axios.get(`simple-query/${moduleId}/${actionName}/${templateGuid}`, {
                    params: merge({}, pager.data(), filter.data()),
                    responseType: 'blob',
                })
            ).data;
        }
    }

    public static async exportStaticElement(
        url: string,
        templateGuid: string,
        filter: any,
        pager: Pager
    ): Promise<any>
    {
        return (
            await axios.get(`simple-query/${url}/${templateGuid}`, {
                params: merge({}, pager.data(), filter.data()),
                responseType: 'blob',
            })
        ).data;
    }

    public static async exportElementNew(endpoint: string, filter: any, pager: Pager): Promise<any>
    {
        // simple-query
        return (
            await axios.get(endpoint, {
                params: merge({}, pager?.data?.() ?? {}, filter?.formatData?.() ?? {}),
                responseType: 'blob',
            })
        ).data;
    }

    public static async exportExcel(
        url: string,
        filter: any,
        pager: Pager,
        actionName: string = '',
        parentType: string = null,
        parentId: string = null
    ): Promise<File>
    {
        if (parentType != null)
        {
            return (
                await axios.get(`simple-query/${parentType}/${parentId}/${url}/excel`, {
                    params: merge({}, filter, pager.data()),
                    responseType: 'blob',
                })
            ).data;
        }

        url = actionName ? `${url}/${actionName}` : url;

        return (
            await axios.get(`simple-query/${url}/excel`, {
                params: merge({}, filter, pager.data()),
                responseType: 'blob',
            })
        ).data;
    }

    public static async exportExcelNew(
        url: string,
        filter: any,
        pager: Pager,
        sitemapId: string = null
    ): Promise<File>
    {
        return (
            await axios.get(`simple-query/${url}/excel`, {
                params: merge({ sitemapId }, filter.formatData(), pager.data()),
                responseType: 'blob',
            })
        ).data;
    }

    public static async fetchLayout(guid: string): Promise<{ displayMode: LayoutDisplayMode }>
    {
        return (await axios.get(`sitemap/${guid}/view-details`)).data;
    }

    public static async fetchSharedFields(guid: string): Promise<any>
    {
        return (await axios.get(`sitemap/${guid}/shared-fields`)).data;
    }

    public static async fetchGroupActions(licence: string, guids: string[]): Promise<any>
    {
        return (
            await axios.get(`simple-query/${licence}/group-actions`, {
                params: { publicIds: guids.join(',') },
            })
        ).data;
    }

    public static async fetchFeatureGroupActions(licence: string, publicId: string, listType: string, guids: string[])
    {
        return (
            await axios.get(`simple-query/${licence}/${publicId}/${listType}/group-actions`, {
                params: { publicIds: guids.join(',') },
            })
        ).data;
    }

    public static async fetchSubprocessActionData(
        licence: string,
        documentGuid: string,
        endpointId: string
    ): Promise<any>
    {
        return (await axios.get(`${licence}/${documentGuid}/subprocess-info/${endpointId}`, {})).data;
    }

    // Calendar
    public static async fetchCalendarSeries(
        licence: string,
        sitemapId: string,
        dateFrom: string,
        dateTo: string,
        filter: any
    ): Promise<Data<Document[]>>
    {
        return (
            await axios.get(
                `simple-query/${licence}/series/calendar?sitemapId=${sitemapId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                {
                    params: filter,
                }
            )
        ).data;
    }

    // Timetable
    public static async fetchTimetableSeries(
        licence: string,
        sitemapId: string,
        dateFrom: string,
        dateTo: string,
        filter: any
    ): Promise<Data<[{ categories: TimetableCategories[] }]>>
    {
        return (
            await axios.get(
                `simple-query/${licence}/series/timetable?sitemapId=${sitemapId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                {
                    params: filter,
                }
            )
        ).data;
    }

    // Chart
    public static async fetchChart(licence: string, sitemapId: string, filter: any): Promise<any>
    {
        return (await axios.get(`simple-query/${licence}/series/chart?sitemapId=${sitemapId}`, { params: filter }))
            .data;
    }

    // Pivot
    public static async fetchPivot(licence: string, sitemapId: string, filter: any): Promise<any>
    {
        return (await axios.get(`simple-query/${licence}/series/pivot?sitemapId=${sitemapId}`, { params: filter }))
            .data;
    }

    public static async fetchPivotSchema(licence: string, sitemapId: string, filter: any): Promise<any>
    {
        return (await axios.get(`simple-schema/${licence}/pivot/result?sitemapId=${sitemapId}`, { params: filter }))
            .data;
    }

    public static async markAsUnseen(licence: string, publicId: string): Promise<any>
    {
        return (await axios.get(`${licence}/${publicId}/mark-as-unseen`)).data;
    }

    public static async groupSetVisibility(moduleGuid: string, model: Record<string, any>): Promise<void>
    {
        return (await axios.post(`modules/${moduleGuid}/field-configs/group-set-visibility`, model)).data;
    }
}

export interface Data<T> {
    config: {
        chartType: KeyValuePair;
        showValues: true;
    };
    series: Series<T>[];
}

export interface Series<T> {
    name: string;
    config: any;
    color: string;
    documents: T;
}

export interface Document {
    publicId: string;
    licence: string;
    title: string;
    description: KeyValuePair[];
    dateFromUtc: DateTime;
    dateToUtc: DateTime;
}

export interface TimetableItems {
    key: string;
    value: string;
    groups: TimetableGroup[];
}

export interface TimetableGroup {
    key: string;
    value: string;
    documents: any[];
}

export interface ChartData {
    data: { amount: number; key: string; value: string }[];
    features: any[];
}

export interface TimetableDocument extends Document {
    color: string;
}

export interface TimetableCategories {
    key: string;
    value: string;
    groups: TimetableGroups[];
}

interface TimetableGroups {
    key: string;
    value: string;
    documents: Document[];
}

export interface ColumnTypeModel {
    baseType: string;
    features: KeyValuePair[];
}

export type Column = {
    columns: { columnName: Record<string, string>; fieldName: string; summary: boolean; formula: string }[];
    type: ColumnTypeModel;
    headerName: string;
    order: number;
    visibility: boolean;
    sort?: string;
    length: number;
};

export interface ColumnDefinitionModel {
    [columnName: string]: Column;
}

export interface AttributesModel {
    is?: string;
    itemKey?: string;
    property: any;
    features: KeyValuePair[];
    isOnGrid?: boolean;
    isInTable?: boolean;
    isExpired?: boolean;
    actions?: Record<string, any>;
    oneDriveSharedDocuments?: Array<Attachment>;
    tableSchema?: {
        head: Column;
        body: {
            [key: string]: Column;
        };
    };
}

export interface IMetaModel {
    can: boolean;
    frontAction: string | null;
    key: string;
    order: number;
    type: number | string;
    value: string | null;
    endpointId?: string;
    displayMode?: DisplayMode;
    displaySize?: DisplaySize;
    url?: string;
    title?: string;
}

export enum LayoutView {
    Grid = 1,
    Calendar,
    Timetable,
    Chart,
    Dashboard,
    Pivot,

    // TODO: In the future, we should remove this
    TaskReport,
    BudgetReport,
}
